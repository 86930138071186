import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import { isMockedChallenge } from '../main/getMockedChallenges';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { toParticipantSections } from './toParticipantSections';
import { getUrlParams } from '../Location/locationProviderPropsMap';
import { resolveProgramId } from '../storage-contexts/Challenge';
import { listSections } from '@wix/ambassador-challenges-v1-challenge/http';
import {
  DescriptionFieldSet,
  ObjectDescription,
} from '@wix/ambassador-challenges-v1-challenge/types';
import {
  listSections as listParticipantSections,
  myProgramSection,
} from '@wix/ambassador-challenges-v1-participant/http';
import { ChallengeSection } from '@wix/ambassador-challenges-v1-participant/types';
import { request } from '../../services/request';
import { patchParticipantSectionList } from '@wix/challenges-web-library';
import { getChallengesListWithMocks } from '../storage-contexts/ChallengesList';
import { isV3enabled } from '../../experiments/isV3enabled';
import { resolveParticipantId } from '../../selectors/participant/resolveParticipantId';

export const isRequiredOwnerData = (flowAPI: ControllerFlowAPI) => {
  const { isEditor, isPreview } = flowAPI.environment;

  return (
    isEditor ||
    isPreview ||
    isForcedPreviewParticipant(
      flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
    )
  );
};

export const loadParticipantSections = async (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext['listParticipantSections']> => {
  const { isEditor } = flowAPI.environment;
  const { programId } = await resolveProgramId(flowAPI);

  let sections: ChallengeSection[] = [];

  if (isMockedChallenge(programId, flowAPI)) {
    return [];
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: true,
  });
  const { participantId } = await resolveParticipantId(flowAPI);
  try {
    sections = (
      await request(
        flowAPI,
        listParticipantSections({
          challengeId: programId,
          participantId,
          descriptionFieldSet: DescriptionFieldSet.STANDARD,
        }),
      )
    )?.data?.sections;
  } catch (error) {
    handleError({ error, context: 'loadSections' });
  }

  if (isRequiredOwnerData(flowAPI) && !sections?.length) {
    const challengeIdForPreview =
      programId ||
      (await getChallengesListWithMocks(flowAPI))?.memberChallenges?.[0]
        ?.challenge?.id;

    if (challengeIdForPreview) {
      try {
        const ownerSections = (
          await request(
            flowAPI,
            listSections({
              challengeId: challengeIdForPreview,
              descriptionFieldSet: DescriptionFieldSet.STANDARD,
            }),
          )
        )?.data?.sections;
        sections = toParticipantSections(ownerSections);
      } catch (error) {
        handleError({
          error,
          context: isEditor
            ? 'loadParticipantSections[editor]'
            : 'loadParticipantSections[preview]',
        });
      }
    }
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: false,
  });

  return patchParticipantSectionList(sections);
};

export async function getSectionNavigationInfo(
  flowAPI: ControllerFlowAPI,
  sections: ChallengeSection[],
): Promise<ChallengeSection | undefined> {
  const urlParams = getUrlParams(flowAPI);
  if (urlParams.navigationType === 'section') {
    const originalSection = sections.find(
      (s) => s.id === urlParams.navigationId,
    );
    if (originalSection || isV3enabled(flowAPI)) {
      return originalSection;
    }
    const { programId } = await resolveProgramId(flowAPI);

    return (
      await request(
        flowAPI,
        myProgramSection({
          programSectionId: urlParams.navigationId,
          programId,
        }),
      )
    )?.data?.participantSection;
  }
}

export const ensureDetails = (
  description: ObjectDescription,
): ObjectDescription => {
  const description_ = description || {};

  description_.details = description_.details || null;

  return description_;
};
